import dynamic from 'next/dynamic';

const pages = {
  'home.HomePage': dynamic(() => import('./HomePage')),
  'flex.FlexPage': dynamic(() => import('./FlexPage')),
  'forms.BaseFormPage': dynamic(() => import('./FormPage')),
  'forms.FlexFormPage': dynamic(() => import('./FormPage')),
  'sick_report.SickReportPage': dynamic(() => import('./SickReportPage')),
  'news.NewsListingPage': dynamic(() => import('./NewsListingPage')),
  'news.NewsPage': dynamic(() => import('./NewsPage')),
  'lexicon.LexiconPage': dynamic(() => import('./LexiconPage')),
  'lexicon.LexiconEntryPage': dynamic(() => import('./LexiconEntryPage')),
  'tender.ApprenticeshipListingPage': dynamic(() => import('./ApprenticeshipListingPage')),
  'tender.ApprenticeshipPage': dynamic(() => import('./ApprenticeshipPage')),
  'tender.JobPostingListingPage': dynamic(() => import('./JobsListingPage')),
  'tender.JobPostingPage': dynamic(() => import('./JobPage')),
  'events.Event': dynamic(() => import('./EventPage')),
  'events.EventListingPage': dynamic(() => import('./EventListingPage')),
  'products.ProductIndexPage': dynamic(() => import('./ProductIndexPage')),
  'products.ProductCategoryIndexPage': dynamic(() => import('./ProductCategoryIndexPage')),
  'products.ProductPage': dynamic(() => import('./ProductPage')),
  'personnel.PersonnelPage': dynamic(() => import('./PersonnelPage')),
  'personnel.EmployeePage': dynamic(() => import('./EmployeePage')),
  'flex.FlexUebersichtPage': dynamic(() => import('./FlexOverview')),
  'department.DepartmentPresentationListingPage': dynamic(
    () => import('./DepartmentPresentationListingPage'),
  ),
  'department.DepartmentPresentationPage': dynamic(() => import('./DepartmentPresentationPage')),
  'contact.ContactListingPage': dynamic(() => import('./ContactListingPage')),
  'contact.ContactPage': dynamic(() => import('./ContactPage')),
  'marketplace.MarketplaceListingPage': dynamic(() => import('./MarketplaceListingPage')),
  'marketplace.MarketplacePage': dynamic(() => import('./MarketplacePage')),
  'tasks.TaskListingPage': dynamic(() => import('./TaskListingPage')),
  'tasks.Task': dynamic(() => import('./TaskPage')),
  'lunch.MenuListingPage': dynamic(() => import('./MenuListingPage')),
  'tasks.FormTask': dynamic(() => import('./FormTask')),
  'shop.ShopPage': dynamic(() => import('./ShopPage')),
  'shop.ArticleItemPage': dynamic(() => import('./ArticleItemPage')),
  'note.NoteListingPage': dynamic(() => import('./NoteListingPage')),
  'note.NotePage': dynamic(() => import('./NotePage')),
  'shop.ShopOrderPage': dynamic(() => import('./ShopOrderPage')),
  'lunch.MenuOrderPage': dynamic(() => import('./MenuOrderPage')),
  'werder_bremen.WerderBremen': dynamic(() => import('./WerderBremenPage')),
  PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
};

export type Pages = keyof typeof pages;

export default pages;
